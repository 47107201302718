import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import { RefreshIcon } from './Banner.icons';
import WhatsAppIcon from '../../assets/icons/whatsapp.svg';
import * as styles from './Banner.module.css';

type InfoBannerProps = {
  banner: {
    title: string;
    active: boolean;
    label: string;
    showNavigation: boolean;
    fixed: boolean;
    centered: boolean;
    auto: boolean;
    autoInterval: number;
    randomStart: boolean;
    backgroundColor: {
      hex: string;
    };
    items: Quote[] | Status[];
  };
};

type Quote = {
  image: {
    url: string;
  };
  imageLink: string;
  quote: string;
  __typename: 'DatoCmsMotivationalQuote';
};

type Status = {
  text: string;
  __typename: 'DatoCmsStatusUpdate';
};

const useTimeout = (callback: () => void, delay: number) => {
  useEffect(() => {
    const id = setTimeout(callback, delay);
    return () => clearTimeout(id);
  }, [callback, delay]);
};

const start = (arr, random) => {
  return random ? Math.floor(Math.random() * arr.length) : 0;
};

export default function InfoBanner({ banner }: InfoBannerProps) {
  const { title, active, label, items, showNavigation, randomStart, auto, autoInterval, fixed } =
    banner;

  const [currentQuote, setCurrentQuote] = useState(start(items, randomStart));

  const interval = autoInterval ?? 4;
  const bgColor = banner.backgroundColor?.hex ?? '#fc99c4';

  const getNewQuote = () => {
    const nextIndex = (currentQuote + 1) % items.length;
    setCurrentQuote(nextIndex);
  };

  useTimeout(() => {
    if (auto) getNewQuote();
  }, interval * 1000);

  const item = items[currentQuote];

  if (!active) return null;

  return (
    <div
      className={`banner ${styles.infoBanner} ${fixed && styles.sticky}`}
      {...(banner.backgroundColor && { style: { backgroundColor: banner.backgroundColor.hex } })}
    >
      <div className={`banner-wrap ${styles.infoBannerWrap}`}>
        <div
          className={styles.innerWrap}
          {...(banner.centered && { style: { justifyContent: 'center' } })}
        >
          {label && (
            <div className={`content-wrap-01 ${styles.contentWrap}`}>
              <div className={styles.label}>{label}</div>
            </div>
          )}

          {item.__typename === 'DatoCmsMotivationalQuote' ? (
            <motion.div
              key={item.quote}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.infoBannerText}
            >
              {item.image?.url &&
                (item.imageLink ? (
                  <a
                    href={item.imageLink}
                    target={item.imageLink.includes('http') ? '_blank' : '_self'}
                    rel="noopener noreferrer"
                  >
                    <motion.img
                      initial={{ scale: 1 }}
                      whileHover={{ scale: 1.1 }}
                      src={item.image.url}
                      style={{
                        borderColor: darken(0.35, bgColor),
                      }}
                      alt="quote"
                    />
                  </a>
                ) : (
                  <img src={item.image.url} alt="quote" />
                ))}
              <div
                dangerouslySetInnerHTML={{
                  __html: item.quote,
                }}
              />
              {/* <a
                href={item.imageLink}
                style={{
                  color: lighten(0.1, bgColor),
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                See more
              </a> */}
            </motion.div>
          ) : (
            <motion.div
              key={item.text}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.infoBannerText}
            >
              {/* TODO: Fix this */}
              {title.includes('Whatsapp') && (
                <img
                  src={WhatsAppIcon}
                  alt="whatsapp"
                  style={{
                    border: 'none',
                    width: '35px',
                    height: '35px',
                    minWidth: '35px',
                  }}
                />
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html: item.text,
                }}
              />
            </motion.div>
          )}
        </div>
        {showNavigation && (
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={getNewQuote}
            className={styles.refreshButton}
            style={{
              backgroundColor: darken(0.1, bgColor),
            }}
          >
            <RefreshIcon />
          </motion.button>
        )}
      </div>
    </div>
  );
}
