import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useQuestionBank = () => {
  const user = useSelector((state) => state?.user?.data);
  return user?.questionBank;
};

export const useIsMainSubscribed = () => {
  const user = useSelector((state) => state?.user?.data);
  return (user?.scopes || []).includes('scope_main_subscription') ?? false;
};

export const useIsQbSubscribed = () => {
  const user = useSelector((state) => state?.user?.data);
  return (user?.scopes || []).includes('scope_question_bank') ?? false;
};

export const useIsSubscribedAny = () => {
  const main = useIsMainSubscribed();
  const qb = useIsQbSubscribed();
  return main || qb;
};

export const useIsSubscribedBoth = () => {
  const main = useIsMainSubscribed();
  const qb = useIsQbSubscribed();
  return main && qb;
};
