import React, { useEffect, useState } from 'react';
import { graphql, navigate, Link, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import { Secondary } from './Secondary';

import FolderIcon from '../../assets/icons/folder.svg';
import Logo from '../../assets/images/logo-small.svg';
import CloseIcon from '../../assets/icons/close.svg';

import searchIcon from '../../assets/icons/search_black_24dp.svg';
import profileIcon from '../../assets/icons/person_outline_black_24dp.svg';
import cartIcon from '../../assets/icons/shopping_cart_black_24dp.svg';
import menuIcon from '../../assets/icons/menu_black_24dp.svg';

import { useSelector, useDispatch } from 'react-redux';
import { login } from '@src/utils/mmc-api/auth';
import { fetchMiniCart, addToCart } from '../../store/cart';
import Search from '../Search';
import OutsideAlerter from '../OutsideAlerter';
import { useIsMdMin } from '@src/hooks/useBreakpoints';
import { useIsSubscribedBoth } from '@src/hooks/user';
import { WhatsappButton } from '../UI/Whatsapp';

export const TOP_NAVIGATION_QUERY = graphql`
  query TopNavigationQuery {
    allDatoCmsNavigation(filter: { name: { in: ["Main", "Secondary"] } }) {
      edges {
        node {
          name
          link {
            label
            slug
            externalLink
            subNavigation {
              ... on DatoCmsModule {
                id
                comingSoon
                shortDescription
                description
                slug
                title
                position
                subCategory {
                  id
                  name
                  slug
                }
                externalLinks {
                  url
                  label
                }
              }
            }
            icon {
              url
            }
          }
        }
      }
    }
  }
`;

const TopNavigation = () => {
  let pageYOffset;
  // Check for the window element.
  // Needed for server size rendering of Gatsby else use of window.innerHeight/Width will cause build failures.
  if (typeof window !== `undefined`) {
    pageYOffset = window.pageYOffset;
  }
  const [isMobNavVisible, setIsMobNavVisible] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const user = useSelector((state) => state.user.data);
  const miniCart = useSelector((state) => new Set(state.cart.miniCart));
  const dispatch = useDispatch();

  const data = useStaticQuery(TOP_NAVIGATION_QUERY);
  const navigations = (navName) =>
    data.allDatoCmsNavigation.edges.filter(({ node }) => node.name === navName);
  const mainNav = navigations('Main')[0].node;
  const secondaryNav = navigations('Secondary')[0].node;

  const isSubscribedBoth = useIsSubscribedBoth();

  const isStg =
    typeof window !== 'undefined' &&
    (window.location.href.startsWith('https://stg') || window.location.href.includes('localhost'));

  const joinText = user?.userId ? 'Upgrade' : 'Join Now';

  const toggleMobileNav = () => {
    setIsMobNavVisible(!isMobNavVisible);
  };

  const isMdMin = useIsMdMin();

  const handleShowDropdown = () => {
    setClicked(!clicked);
    setVisible(true);
  };

  const handleHideDropdown = () => {
    setClicked(false);
    setVisible(false);
  };

  const handleBack = () => {
    setClicked(false);
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  useEffect(() => {
    let cart;
    if (localStorage?.cart) {
      cart = JSON.parse(localStorage?.cart);
    }
    if (localStorage.cart?.length > 0 && user?.email) {
      dispatch(addToCart(cart, user));
      localStorage.removeItem('cart');
    }
    if (user && user?.email) {
      dispatch(fetchMiniCart(user)).then(setReRender(!reRender));
    }
  }, [user]);

  const renderExternalLink = (externalLink) => (
    <li className="nav-module-list-item" key={externalLink.url}>
      <img src={FolderIcon} alt="Folder" className="nav-module-image" />
      <Link
        className="nav-module-link"
        to={externalLink.url}
        onClick={() => {
          setVisible(false);
        }}
      >
        {externalLink.label}
      </Link>
    </li>
  );

  const renderSubCategory = (subNavSlug, subCategory) => (
    <li className="nav-module-list-item" key={subCategory.id}>
      <img src={FolderIcon} alt="Folder" className="nav-module-image" />
      <Link
        className="nav-module-link"
        to={`/modules/${subNavSlug}/${subCategory.slug}`}
        onClick={() => {
          setVisible(false);
        }}
      >
        {subCategory.name}
      </Link>
    </li>
  );

  const renderSubNavigation = (subNav) => (
    <div className="navigation-link-block" key={subNav.slug}>
      <div className="nav-content-wrap">
        <Link
          className="nav-module-link title"
          to={`/modules/${subNav.slug}`}
          onClick={() => {
            setVisible(false);
          }}
        >
          <h4>{subNav.title}</h4>
        </Link>
        <ReactMarkdown className="nav-link-details" children={subNav.shortDescription} />
        <ul>
          {subNav.subCategory.map((subCategory) => renderSubCategory(subNav.slug, subCategory))}
        </ul>
        <ul>{subNav.externalLinks.map((externalLink) => renderExternalLink(externalLink))}</ul>
      </div>
    </div>
  );

  const renderDropdown = (link) => {
    return (
      <li
        className={`has-dropdown${clicked ? ' moved' : ''}`}
        key={link.label}
        {...(isMdMin
          ? {
              onPointerEnter: handleShowDropdown,
              onPointerLeave: handleHideDropdown,
            }
          : {
              onClick: handleShowDropdown,
            })}
      >
        <button>{link.label}</button>
        {visible && (
          <ul className={`dropdown m-menu ${!visible ? 'is-hidden' : ''}`}>
            <li className="title back">
              <h5>
                <button onClick={handleBack}>Back</button>
              </h5>
            </li>
            <li className="dropdown-list-full-wrapper">
              <p className="scroll-modules-text">Scroll Down For More Modules/Education Levels</p>
              <div className="row navigation-drop-container">
                {link.subNavigation.map(renderSubNavigation)}
              </div>
            </li>
          </ul>
        )}
      </li>
    );
  };

  const renderMainLink = (link) => {
    return link.subNavigation.length ? (
      renderDropdown(link)
    ) : (
      <li key={link.slug}>
        <Link
          className="navigation-link w-nav-link"
          to={link.externalLink ? link.externalLink : `/${link.slug === 'home' ? '' : link.slug}`}
        >
          {link.label}
        </Link>
      </li>
    );
  };

  const renderNav = (mainNav, secondaryNav) => (
    <div className={`navigation-wrap`}>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        role="banner"
        className="navigation w-nav"
      >
        <div className="navigation-full">
          <div className="nav-top">
            <div className="navigation-container-2">
              <div className="nav-top-container">
                <div className="flex flex-center">
                  <WhatsappButton />

                  {!isMdMin && (
                    <Link href="/question-bank" className="button w-button">
                      Question Bank
                    </Link>
                  )}
                </div>

                {isMdMin && (
                  <Link href="/question-bank" className="button w-button">
                    Question Bank
                  </Link>
                )}

                <div className="nav-top-links">
                  <div className="navigation-right-2">
                    <div className="nav-top-main-links flex flex-center">
                      <div className="w-nav-brand" onClick={() => setShowSearch(true)}>
                        <img src={searchIcon} alt="searchIcon" />
                      </div>
                      <Link to="/cart" className="w-nav-brand">
                        <img src={cartIcon} alt="cartIcon" />
                        {miniCart.size > 0 ? <span className="cartSize">{miniCart.size}</span> : ''}
                      </Link>
                    </div>

                    {user?.email ? (
                      <div style={{ marginRight: 5 }}>
                        <Link
                          to="/profile"
                          className="flex flex-center w-nav-brand"
                          style={{ gap: 3 }}
                        >
                          <img className="icon" src={profileIcon} alt="profileIcon" />
                          <p>Hi {user.fullname ? user.fullname : user.username}</p>
                        </Link>
                      </div>
                    ) : (
                      <button onClick={login} className="w-nav-brand sign-in-badge">
                        <span>Sign In</span>
                      </button>
                    )}

                    {!isSubscribedBoth && (
                      <button
                        onClick={() => navigate('/subscriptions')}
                        className="w-nav-brand sign-in-badge"
                      >
                        <span>{joinText}</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navigation-container-2">
            <div className="navigation-left-2">
              <Link to="/" className="brand w-nav-brand w--current">
                <img src={Logo} width="80" alt="Logo" className="image-2" />
              </Link>
              <div className="nav-wrapper">
                <section className="top-bar-section">
                  <ul className="left">{mainNav.link.map(renderMainLink)}</ul>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation-container-2" style={{ display: 'block' }}>
          <Secondary links={secondaryNav.link} />
        </div>
      </div>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        data-easing="ease-out"
        data-easing2="ease-out"
        role="banner"
        className="navigation-mob w-nav"
      >
        <div className="navigation-container-mob">
          <Link to="/" className="w-nav-brand">
            <img src={Logo} alt="Logo" />
          </Link>
          <div className="flex flex-center flex-column">
            <div className="mob-container">
              <div>
                <div className="w-nav-brand" onClick={() => setShowSearch(true)}>
                  <img src={searchIcon} alt="searchIcon" />
                </div>
              </div>
              <div>
                <Link to="/cart" className="w-nav-brand">
                  <img src={cartIcon} alt="cartIcon" />
                  {miniCart.size > 0 ? <span className="cartSize">{miniCart.size}</span> : ''}
                </Link>
              </div>
              <div>
                <button className="w-nav-button" onClick={toggleMobileNav}>
                  <img src={menuIcon} alt="menuIcon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav
        role="navigation"
        className="mobile-nav w-nav-menu"
        style={{
          display: isMobNavVisible ? 'block' : 'none',
        }}
      >
        <div className="mobile-nav-header">
          <Link to="/" className="w-nav-brand">
            <img src={Logo} alt="Logo" />
          </Link>
          <button className="close-button" onClick={() => setIsMobNavVisible(false)}>
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div>
          <section className="top-bar-section" style={{ left: clicked ? '-100%' : '0%' }}>
            <ul className="left">
              {mainNav.link.map(renderMainLink)}
              {user?.email &&
                renderMainLink({
                  label: 'Profile',
                  slug: 'profile',
                  icon: null,
                  subNavigation: [],
                  externalLink: '',
                })}
            </ul>
          </section>
          <Secondary links={secondaryNav.link} />
        </div>
      </nav>
    </div>
  );

  return (
    <>
      <nav className={`top-bar${isMobNavVisible ? ' expanded' : ''}`} data-topbar role="navigation">
        {renderNav(mainNav, secondaryNav)}
      </nav>
      {showSearch && isStg && (
        <OutsideAlerter onOutsideClick={() => setShowSearch(false)}>
          <Search onEscape={() => setShowSearch(false)} />
        </OutsideAlerter>
      )}
    </>
  );
};

export { TopNavigation };
