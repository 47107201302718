import { useMedia } from './useMedia';

const breakpoints = {
  sm: '576px',
  md: '991px',
};

type useBreakpointProps = {
  screen: keyof typeof breakpoints;
  modifier?: 'min-width' | 'max-width';
};

export const useBreakpoint = ({ screen, modifier = 'min-width' }: useBreakpointProps) =>
  useMedia(`(${modifier}: ${breakpoints[screen]})`);

export const useIsMobile = () => useBreakpoint({ screen: 'sm', modifier: 'max-width' });
export const useIsMdMax = () => useBreakpoint({ screen: 'md', modifier: 'max-width' });
export const useIsMdMin = () => useBreakpoint({ screen: 'md', modifier: 'min-width' });
