import React from 'react';
import WhatsAppIcon from '../../../assets/icons/whatsapp.svg';

const WhatsappButton = () => {
  return (
    <a
      href="https://api.whatsapp.com/send/?phone=447704004444&text=Hi+there%2C+I+am+getting+in+touch+via+MyMathsCloud.com&type=phone_number&app_absent=0"
      target="_blank"
      rel="noreferrer noopener"
      className="whatsapp"
      style={{
        margin: 0,
      }}
    >
      <img
        src={WhatsAppIcon}
        alt="whatsapp"
        style={{
          border: 'none',
          width: '15px',
          height: '15px',
          minWidth: '15px',
        }}
      />
      <span>Get In Touch</span>
    </a>
  );
};

export default WhatsappButton;
