import React from 'react';

const NavLink = ({ link }) => {
  const url = link.externalLink || link.slug;

  return (
    <a
      href={url}
      className="navigation-link w-nav-link"
      style={{ fontSize: 12 }}
      {...(link.externalLink && { target: '_blank', rel: 'noreferrer' })}
    >
      {link.icon && link.icon.url && (
        <img src={link.icon.url} style={{ height: 12, marginRight: 5 }} alt="Link" />
      )}{' '}
      {link.label}
    </a>
  );
};

const Secondary = ({ links }) => {
  const iconLinks = links.filter((link) => link.icon);
  const textLinks = links.filter((link) => !link.icon);

  return (
    <nav role="secondary navigation" className="nav-menu-2 w-nav-menu">
      <div className="secondary-nav">
        {iconLinks.map((link) => (
          <NavLink link={link} />
        ))}
      </div>
      <div className="secondary-nav">
        {textLinks.map((link) => (
          <NavLink link={link} />
        ))}
      </div>
    </nav>
  );
};

export { Secondary };
